<template>
  <div class="page select-payment-for-add more-depth">
    <div class="container">
      <div class="page-header">
        <div class="page-header__title">
          <h1 class="text">
            {{ $__t('결제방식 선택') }}
          </h1>
        </div>
      </div>

      <div class="page-body">
        <SelectPayment :redirect="redirect" />
      </div>
    </div>
  </div>
</template>

<script>
import SelectPayment from '@/v3/components/resources/payment/SelectPayment.vue';

export default {
  components: {
    SelectPayment
  },
  props: {
    redirect: {
      type: String,
      default: 'Home'
    }
  }
};
</script>

<style lang="scss">
.page.select-payment-for-add {
  background-color: #fff;
}
</style>