<template>
  <div class="resource lg-select" :class="{ hover: state.hover, error: error, disabled: disabled }">
    <div v-if="label" class="label">{{ label }}</div>
    <div class="body">
      {{ displayValue ? value : displayLabel }}
      <i class="fa fa-angle-down"></i>
    </div>
    <select ref="input" v-model="model" :disabled="disabled">
      <option v-if="placeholder" value="">{{ placeholder }}</option>
      <option v-for="item in items" :value="item.value">{{ item.label }}</option>
    </select>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: ['value', 'items', 'placeholder', 'label', 'error', 'disabled', 'displayValue'],
  data() {
    var state = { hover: false, error: false };
    return { state };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        this.$emit('select', value);
      }
    },
    displayLabel() {
      if (typeof this.value == 'number' || this.value) {
        if (this.items.filter((o) => o.value == this.value)[0] && this.items.filter((o) => o.value == this.value)[0].label) {
          return this.items.filter((o) => o.value == this.value)[0].label;
        } else {
          if (this.label) {
            this.$emit('input', '');
            return '';
          } else {
            this.$emit('input', this.placeholder);
            return this.placeholder;
          }
        }
      } else {
        if (this.label) {
          return '';
        } else {
          return this.placeholder;
        }
      }
    }
  },
  mounted() {
    if (!this.$store.state.config.isTouchDevice) {
      this.$refs.input.addEventListener('mouseover', (e) => (this.state.hover = true));
      this.$refs.input.addEventListener('mouseout', (e) => (this.state.hover = false));
    }
  }
};
</script>

<style lang="scss">
.lg-select {
  font-size: $font-size-normal;
  line-height: unit(20);

  select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }

  .label {
    position: absolute;
    top: unit(-8);
    left: unit(12);
    height: unit(16);
    font-size: unit(12);
    white-space: nowrap;
    z-index: 1;
    transition: all 0.2s;
    background-color: #fff;
    padding: 0 unit(8);
  }

  .body {
    border-radius: $radius;
    height: 100%;
    padding: unit(15) unit(20);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: all 0.2s;
  }

  i {
    position: absolute;
    right: 0;
    bottom: 0;
    width: unit(40);
    height: 100%;
    text-align: center;
    line-height: unit(40);
    color: $color-gray-3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .error {
    font-size: $font-size-small;
    color: $color-red;
  }

  & {
    .label {
      color: $color-gray-5;
    }
    .body {
      box-shadow: inset 0 0 0 1px $color-gray-3;
    }
  }

  &.hover {
    .body {
      background-color: $color-primary-0;
    }
  }

  &.disabled {
    background-color: $color-gray-1;
    color: $color-gray-5;
    select {
      cursor: not-allowed;
    }
  }
}
</style>
