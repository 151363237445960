<template>
  <div class="resource payment-list">
    <ul>
      <li v-for="item in items" :key="item.uid">
        <div class="payment" @click="onClickSelectPayment(item)" v-if="item.gateway === 'KAKAOPAY'">
          <img src="@/assets/icons/svg/payment-kakaopay.svg" class="icon" />
          <span>{{ $__t(`${item.label}`) }}</span>
          <i class="fa fa-angle-right"></i>
        </div>

        <div class="payment" @click="onClickSelectPayment(item)" v-else>
          <span>{{ item.label }}</span>
          <i class="fa fa-angle-right"></i>
        </div>
      </li>
    </ul>
    <modal
      id="noregister-kakaopay-modal"
      ref="noregister-kakaopay-modal"
      :transparent="true"
      :hide-footer="false"
      @submit="onSubmitForKakaoPayWarning"
      submitButtonLabel="확인"
    >
      <template v-slot:body>
        <div>{{ $__t('카카오페이를 이미 등록한 경우,') }}<br />{{ $__t('재등록은 불가합니다.') }}</div>
      </template>
      <template v-slot:footer>
        <button>{{ $__t('확인') }}</button>
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'PaymentList',
  props: {
    country: {
      type: String
    }
  },
  computed: {
    items() {
      return this.paymentItems.filter((item) => {
        if (this.$props.country === 'KR') {
          return item.country === 'KR';
        }

        return item.country !== 'KR';
      });
    },
    billings() {
      return Object.keys(this.$store.state.billings.items)
        .map((o) => this.$store.state.billings.items[o])
        .sort((a, b) => {
          const A_CREATD_AT = this.$moment(a.created_at);
          const B_CREATD_AT = this.$moment(b.created_at);

          if (A_CREATD_AT.isBefore(B_CREATD_AT)) {
            return 1;
          }

          if (B_CREATD_AT.isBefore(A_CREATD_AT)) {
            return -1;
          }

          return 0;
        });
    }
  },
  methods: {
    onSubmitForKakaoPayWarning() {
      this.closeModal('noregister-kakaopay-modal');
      this.$router.go(-1);
    },
    onClickSelectPayment(item) {
      if (item.gateway === 'NICEPAY') {
        this.$router.push({
          name: 'AddPaymentNicePay',
          query: this.$route.props || this.$route.query
        });
      } else if (item.gateway === 'EXIMBAY') {
        this.$router.push({
          name: 'AddPaymentEximbay',
          query: this.$route.props || this.$route.query
        });
      } else if (item.gateway === 'KAKAOPAY') {
        let alreadyRegisterKakaopay = this.billings?.some((billing) => {
          return billing.billing_gateway === 'KAKAOPAY';
        });
        if (alreadyRegisterKakaopay) {
          return this.openModal('noregister-kakaopay-modal');
        }
        this.$router.push({
          name: 'AddPaymentKakaoPay',
          query: this.$route.props || this.$route.query
        });
      }
    },
    openModal(ref) {
      this.$refs[ref].open();
    },
    closeModal(ref) {
      this.$refs[ref].close();
    }
  },
  data() {
    return {
      paymentItems: [
        { gateway: 'NICEPAY', method: 'BILLING', country: 'KR', label: this.$__t('신용카드') },
        { gateway: 'KAKAOPAY', method: 'BILLING', country: 'KR', label: this.$__t('카카오페이') },
        { gateway: 'EXIMBAY', method: 'BILLING', country: 'OTHER', label: this.$__t('신용카드') }
      ]
    };
  }
};
</script>

<style lang="scss">
.resource.payment-list {
  ul {
    li {
      padding: unit(4) 0;
    }
  }

  .payment {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #dbdee2;
    height: unit(50);
    border-radius: unit(8);
    padding: 0 unit(16);

    .icon {
      width: unit(50);
      margin-right: unit(8);
    }

    span {
      font-size: unit(16);
      font-weight: 500;
    }

    i {
      position: absolute;
      right: 0;
      bottom: 0;
      width: unit(40);
      height: 100%;
      text-align: center;
      line-height: unit(40);
      color: $color-gray-3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
