import countryCodes from '@/assets/json/country-codes.json';
import config from '@/config';

export function getCountryItems() {
  // prettier-ignore
  let majorCountryCodes = [
    'CA', 'US', 'GB', 'FR', 'DE', 'CN', 'RU', 'MN', 'JP', 'TW', 
    'PH', 'HK', 'TH', 'VN', 'IN', 'MY', 'SG', 'AU', 'ID', 'KR'
  ];
  var items = countryCodes
    .map((o) => {
      return { name: o.name, code: o.code };
    })
    .filter((o) => {
      return majorCountryCodes.includes(o.code);
    })
    .sort((a, b) => {
      return a.code.toUpperCase() < b.code.toUpperCase() ? -1 : a.code.toUpperCase() > b.code.toUpperCase() ? 1 : 0;
    });

  items.forEach((o) => {
    if (o.name === 'Korea, Republic of') {
      items.splice(items.indexOf(o), 1);
      items.unshift(o);
    }

    if (o.name === 'Others') {
      items.splice(items.indexOf(o), 1);
      items.push(o);
    }
  });
  items.push({ name: 'Others', code: 'ETC' });

  return items.map((o) => {
    return { label: o.code + ' (' + o.name + ')', value: o.code };
  });
}

export function getCurrntCountry(auth) {
  if (auth.user_iso_code && auth.user_dial_code) {
    return auth.user_iso_code;
  } else if (config.userLocale.indexOf('ko') !== -1) {
    return 'KR';
  }

  return '';
}
