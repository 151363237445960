<template>
  <div class="resource select-payment">
    <div class="section country">
      <h3>{{ $__t('국가 선택') }}</h3>
      <UiSelect v-model="country" :items="countryItems" :placeholder="$__t('결제방식을 선택할 국가를 선택해주세요.')" />
    </div>

    <div class="section list">
      <h3>{{ $__t('결제 방법') }}</h3>
      <PaymentList :redirect="redirect" :country="country" />
    </div>

    <p>{{ $__t('결제 수단 등록/변경 시 인증을 위해 100원 결제 후 즉시 자동 환불됩니다.') }}</p>
    <!-- <p>{{ $__t('유효성 검증을 위해 100원이 가결제됩니다.') }}</p> -->
  </div>
</template>

<script>
import UiSelect from '@/v3/components/common/form/Select.vue';
import PaymentList from '@/v3/components/resources/payment/PaymentList.vue';
import { getCountryItems, getCurrntCountry } from '@/v3/composable/country';

export default {
  components: {
    UiSelect,
    PaymentList
  },
  props: {
    redirect: {
      type: String,
      default: 'Home'
    }
  },
  data() {
    return {
      country: '',
      countryItems: []
    };
  },
  created() {
    this.$data.countryItems = getCountryItems();
    this.$data.country = getCurrntCountry(this.$store.state.auth);
  }
};
</script>

<style lang="scss">
.resource.select-payment {
  background-color: #fff;

  .section {
    margin-top: unit(32);

    &:first-child {
      margin-top: unit(8);
    }

    h3 {
      font-size: unit(14);
    }
  }

  p {
    margin-top: unit(32);
    font-size: unit(12);
    color: #666;
  }
}
</style>
